.cm-s-codaisseur.CodeMirror {
  background: white;
  color: #101214;
  border: 1px solid #e3e7ea;
  border-left: 3px solid #3e454b;
  // box-shadow: $box-shadow-sm;

  font-family: Menlo, Consolas, "Ubuntu Mono", monospace;
  font-size: 13px;

  height: 26rem;
}
.cm-s-codaisseur div.CodeMirror-selected {
  background: #d6d5d4;
}

.cm-s-codaisseur .CodeMirror-line::selection,
.cm-s-codaisseur .CodeMirror-line > span::selection,
.cm-s-codaisseur .CodeMirror-line > span > span::selection {
  background: #d6d5d4;
}
.cm-s-codaisseur .CodeMirror-line::-moz-selection,
.cm-s-codaisseur .CodeMirror-line > span::-moz-selection,
.cm-s-codaisseur .CodeMirror-line > span > span::selection {
  background: #d9d9d9;
}

.cm-s-codaisseur .CodeMirror-gutters {
  background: white;
  border-right: 1px solid #e3e7ea;
}
.cm-s-codaisseur .CodeMirror-guttermarker {
  color: #c1272d;
}
.cm-s-codaisseur .CodeMirror-guttermarker-subtle {
  color: #807d7c;
}
.cm-s-codaisseur .CodeMirror-linenumber {
  color: #807d7c;
}

.cm-s-codaisseur .CodeMirror-cursor {
  border-left: 1px solid #5c5855;
}

.cm-s-codaisseur span.cm-header {
  font-weight: bold;
}
.cm-s-codaisseur span.cm-comment {
  color: #8c959d;
}
.cm-s-codaisseur span.cm-atom {
  color: #a16a94;
}
.cm-s-codaisseur span.cm-number {
  color: #a16a94;
}

.cm-s-codaisseur span.cm-property,
.cm-s-codaisseur span.cm-attribute {
  color: #2a7e5a;
}
.cm-s-codaisseur span.cm-keyword {
  color: #c1272d;
}
.cm-s-codaisseur span.cm-string {
  color: #26a69a;
}

.cm-s-codaisseur span.cm-variable {
  color: #2a7e5a;
}
.cm-s-codaisseur span.cm-variable-2 {
  color: #0088fa;
}
.cm-s-codaisseur span.cm-def {
  color: #e8bbd0;
}
.cm-s-codaisseur span.cm-bracket {
  color: #3a3432;
}
.cm-s-codaisseur span.cm-tag {
  color: #c1272d;
}
.cm-s-codaisseur span.cm-link {
  color: #a16a94;
}
.cm-s-codaisseur span.cm-error {
  background: white;
  color: #c1272d;
}

.cm-s-codaisseur .CodeMirror-activeline-background {
  background: #e8f2ff;
}
.cm-s-codaisseur .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #a16a94 !important;
}
